import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { PageRequest } from '../../shared/model/page-request.model';
import { Observable } from 'rxjs';
import { PageResult } from '../../shared/model/page-result.model';
import { Template } from '../model/template.model';
import { TemplateView } from '../model/template-view.model';
import { TemplateRevisionInfo } from './template-revision.info';

@Injectable({
  providedIn: 'root',
})
export class TemplateHttpService {
  private readonly templateBaseUrl = `${environment.baseUrl}/template`;

  constructor(private httpClient: HttpClient) {}

  getAll(pageRequest: PageRequest): Observable<PageResult<TemplateView>> {
    const params = pageRequest.toParams();
    return this.httpClient.get<PageResult<TemplateView>>(
      `${this.templateBaseUrl}/view`,
      { params },
    );
  }

  getNames(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.templateBaseUrl}/names`);
  }

  getById(templateId: string): Observable<Template> {
    return this.httpClient.get<Template>(
      `${this.templateBaseUrl}/${templateId}`,
    );
  }

  save(template: Template): Observable<string> {
    return this.httpClient.post(`${this.templateBaseUrl}`, template, {
      responseType: 'text',
    });
  }

  isExpressionValid(
    expression: string,
    isCondition: boolean,
  ): Observable<boolean> {
    return this.httpClient.post<boolean>(
      `${this.templateBaseUrl}/expressionValid`,
      { expression, isCondition },
    );
  }

  getDownloadUrl(templateId: string): string {
    return `${this.templateBaseUrl}/${templateId}/download`;
  }

  override(templateId: string, file: File): Observable<void> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post<void>(
      `${this.templateBaseUrl}/${templateId}/overwrite`,
      formData,
    );
  }

  copyTemplate(templateId: string): Observable<void> {
    return this.httpClient.post<void>(
      `${this.templateBaseUrl}/${templateId}/copy`,
      {},
    );
  }

  deleteTemplate(templateId: string): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.templateBaseUrl}/${templateId}`,
    );
  }

  getRevisionInfos(templateId: string): Observable<TemplateRevisionInfo[]> {
    return this.httpClient.get<TemplateRevisionInfo[]>(
      `${this.templateBaseUrl}/${templateId}/revisions`,
    );
  }
}
